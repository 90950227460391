export default [
    {
        header: 'Employee Panel',
        action: 'employee',
        resource: 'Employee',
    },
    {
        title: 'Student Task',
        icon: 'TargetIcon',
        children: [
            {
                action: 'home work',
                resource: 'Employee Task',
                title: 'Home Works',
                route: 'employee-task-homework',
            },
            {
                action: 'course material',
                resource: 'Employee Task',
                title: 'Course Materials',
                route: 'employee-task-course-material',
            },
            {
                action: 'attendance',
                resource: 'Employee Task',
                title: 'Attendance',
                route: 'employee-task-attendance',
            },
            {
                action: 'mark upload',
                resource: 'Employee Task',
                title: 'Mark Upload',
                route: 'employee-task-mark-upload',
            },
        ]
    }
]
