/*import store from '@/store'
let public_menus = store.getters.public_menus;*/
const menu=[
    {
        header: 'Public Site',
        action: 'read',
        resource: 'Pubic Site',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Menu',
        route: 'public.menu',
        icon: 'MenuIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Sub Menu',
        route: 'public.sub.menu',
        icon: 'KeyIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Home Page',
        route: 'public.home.page',
        icon: 'HomeIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Chief Message',
        route: 'public.principal.message',
        icon: 'MessageCircleIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Facilities',
        route: 'public.facilities',
        icon: 'GiftIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Marquee Manage',
        route: 'public.marquee',
        icon: 'TypeIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Slider',
        route: 'public.slider',
        icon: 'LayoutIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Contact Information',
        route: 'public.contact.information',
        icon: 'MapIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Contact Us',
        route: 'public.contact.us',
        icon: 'TypeIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Subscriber',
        route: 'public.subscriber',
        icon: 'MailIcon',
    },
    {
        action: 'read',
        resource: 'Pubic Site',
        title: 'Social Link',
        route: 'public.social.link',
        icon: 'SmileIcon',
    },
    {
        title: 'Footer',
        icon: 'BookIcon',
        children: [
            {
                action: 'read',
                resource: 'Pubic Site',
                title: 'Information About',
                route: { name: 'public.information.about', params: { type: 1 } },
            },
            {
                action: 'read',
                resource: 'Pubic Site',
                title: 'Information For',
                route: { name: 'public.information.for', params: { type: 2 } },
            },
            {
                action: 'read',
                resource: 'Pubic Site',
                title: 'Quick Link',
                route: { name: 'public.quick.link', params: { type: 3 } },
            },
        ]
    },
];

export default menu;
